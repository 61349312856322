import * as React from "react"
import Layout from "../components/layout"
import * as fourOhFourStyles from "./404.module.scss"
import { StaticImage } from "gatsby-plugin-image"

const NotFoundPage = () => (
  <Layout>
    <div className={fourOhFourStyles.wrapper}>
      <div
        className={fourOhFourStyles.heroImageWrapper}
        id="inner-header-1-hero"
      >
        <StaticImage
          src="../assets/images/global/page-partials/inner-header-1/factory-MB.png"
          className={fourOhFourStyles.heroImageMB}
          alt="Vintage image of shoe factory"
        />
        <StaticImage
          src="../assets/images/global/page-partials/inner-header-1/factory-TB.png"
          className={fourOhFourStyles.heroImageTB}
          alt="Vintage image of shoe factory"
        />
        <StaticImage
          src="../assets/images/global/page-partials/inner-header-1/factory-DT.png"
          className={fourOhFourStyles.heroImageDT}
          alt="Vintage image of shoe factory"
        />
      </div>
    </div>
    <div className="inner-container inner-y-padding">
      <h1>404: Not Found</h1>
      <p>
        You just hit a route that doesn&#39;t exist. Try the navigation or
        search bar above.{" "}
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
